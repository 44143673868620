.container {
    background-color: #f1f1f1;
    position: relative;
}

.mainWithAudio{
    margin-top: 1em;
    margin-bottom: 1em;
    box-shadow: 0 0 10px #ccc;
}

.dropdown {
    display: block;
    margin: 0.5em auto;
    width: 100px;
}

.demoNavItem {
    display: block;
    margin: 0.5rem auto;
    padding: 0.75em 1.5em;
    background-color: #1E90FF;
    color: white;
    font-size: 1em;
    border: none;
    border-radius: 25px;
    box-sizing: border-box;
    text-decoration: none;
    white-space: nowrap;
    cursor: pointer;
}

@media (min-width: 1020px) {
    .aside {
        margin-left: auto;
    }

    .mainWithAudio{
        margin-right: 20%;
        margin-left: 20%;
    }
}

/* Desktop size */
@media  (min-width: 769px) and (max-width: 1020px){
    .grid {
        margin-top: 1em;
        margin-left: 1em;
        margin-right: 1em;
    }

    .row {
        display: grid;
        /* grid-template-columns: 1fr 3fr; */
        grid-column-gap: 1em;
    }

    .mainWithAudio{
        margin-top: 1em;
        margin-right: 0;
        margin-left: 0;
    }
}

/* Ipad */
@media all and (device-width: 768px) and (device-height: 1024px) and (orientation: portrait) {

    .aside {
        margin-left: auto;
    }

    .main {
        margin-top: 1em;
        margin-right: auto;
    }

    .mainWithAudio{
        margin-top: 1em;
        margin-left: 0;
        margin-right: 0;
        box-shadow: 0 0 10px #ccc;
    }
}

@media all and (device-height: 768px) and (device-width: 1024px) and (orientation: landscape) {

    .aside {
        margin-left: auto;
    }

    .mainWithAudio{
        margin-left: 0;
        margin-right: 0;
    }
}

/* Mobile devices - excluding ipad*/
@media (max-width: 767px) {
    .row {
        display: block;
    }

    .aside{
        margin-top: 0;
    }

    .mainWithAudio{
        margin-left: 0;
        margin-right: 0;
    }
}

.unselectable {
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Desktop size */
@media (min-width: 768px) {

    .markers {
        display: grid;
        grid-template-columns: 1fr 1fr;
    }

    .WrapperBlock {
        margin-bottom: 1em;
        overflow: hidden;
        display: grid;
        grid-template-columns: [col-speaker] minmax(200px, 18%) [col-text] minmax(
      50%,
      81%
    );
        grid-column-gap: 1%;
        justify-content: center;
        align-content: center;
    }

    .loadingBlockPlaceholder{
        display: flex;
        width: 100%;
        min-height: 50px;
        font-weight: bold;
        justify-content: center;
        margin: 10px 0;
        align-items: center;
        justify-items: center;
        background-color: #f7f7f7;
    }

    .speaker {
        color: #696969;
        font-weight: bold;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        justify-self: start;
        max-width: 100%;
        text-align: left;
    }

    .text {
        font-size: 1em;
        float: right;
        clear: right;
    }
}


.time {
    font-weight: lighter;
    cursor: pointer;
    justify-self: start;
}

.time:hover {
    text-decoration: underline;

    /* color: blue; */
}

.speakerNotEditable {
    cursor: no-drop;
}


/* Mobile devices */
@media (max-width: 768px) {

    .markers {
        width: 100%;
        font-size: 0.8em;
        line-height: 1.2em;
        margin-bottom: 0.5em;
    }

    .WrapperBlock {
        width: 100%;
        margin-bottom: 1em;
        line-height: 1.2em;
        overflow: hidden;
    }

    .speaker {
        padding-right: 2em;
        vertical-align: middle;
        color: #696969;
        font-weight: bold;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        text-align: left;
    }

    .text {
        width: 100%;
        font-size: 1em;
        float: left;

        /* clear: right; */
    }
}
body {
    margin: 0;
    padding: 0.5em;
}

.container {
    height: 100vh;
    font-family: ReithSerif, sans-serif;
}

/* phone */
@media (max-width: 767px) {
    body {
        padding: 0;
    }
}
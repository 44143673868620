.DraftEditor-root {
    background: #f9f9f9;
}

.editor :global(.public-DraftEditor-content) {
    max-height: calc(100vh - 440px);
    overflow: auto;
    padding: 8px 16px;
    background-color: white;
}

/* Mobile devices */
@media (max-width: 768px) {
    .editor :global(.public-DraftEditor-content) {
        margin: 0 auto;
    }
}
@import '../../colours.scss';

@font-face{
  font-family:'digital-clock-font';
  src: url('../../../fonts/digital-7.regular.ttf');
}

.playerControls {
  margin-bottom: 0.5em;
}

.playerControls > * {
  display: inline-block;
}

.playerControls > *:not(:last-child) {
  margin-right: 0.5em;
}

.timeBox {
  display: inline-block;
  text-align: center;
  line-height: 3em;
  border-radius: 20em;
  padding: 0 1em;
  background-color: $color-dodger-blue;
  margin-bottom: 2em;

  .currentTime {
    color: #00FA9A;
    cursor: pointer;
    font-size: 2em;
    font-family: 'digital-clock-font', serif;
  }

  .separator {
    color: white;
    margin: 0 0.5em;
    font-size: 2em;
  }

  .duration {
    color: white;
    font-size: 2em;
    font-family: digital-clock-font, serif;
  }
}

.btnsGroup {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 0.2em;
}
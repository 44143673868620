@font-face{
  font-family:'digital-clock-font';
  src: url('../../fonts/digital-7.regular.ttf');
}

.playerSection {
  display: inline-flex;
  align-items: flex-start;
  width: 100%;
}

.controlsSection {
  text-align: center;
  width: 100%;
  margin: auto;
  padding: 1em;
  position: relative;
}
